<template>
  <v-row>
    <div class="d-flex flex-wrap">
      <template
        v-if="!isLoading"
      >
        <people-list-content
          v-for="(person, i) in people"
          :id="person.id"
          :key="i"
          :index="i"
          @onSelectPerson="onSelectPerson"
        />
      </template>
      <template v-else>
        <v-skeleton-loader
          v-for="n in 5"
          :key="n"
          type="image"
          :style="{
            'aspect-ratio': '1',
            height: $vuetify.breakpoint.mobile? '80px' : '100px',
            width: $vuetify.breakpoint.mobile? '80px' : '100px',
            margin: '4px',
          }"
        />
      </template>
    </div>
  </v-row>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import PeopleListContent from './PeopleListContent.vue';

export default {
  name: 'PeopleList',
  components: {
    PeopleListContent,
  },
  props: {
    people: { type: Array, default: undefined },
    isLoading: Boolean,
  },
  methods: {
    onSelectPerson(index) {
      this.$emit('onSelectPerson', index);
    },
  },
};
</script>
