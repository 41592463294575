<template>
  <v-btn
    :type="type"
    :color="color"
    :outlined="outlined"
    :disabled="disabled"
    :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
    elevation="0"
    :loading="isLoading"
    class="d-flex custom-transform-class text-none px-3"
    :class="`${contentColor || 'white'}--text`"
    :x-small="$vuetify.breakpoint.mobile"
    @click="$emit('clicked')"
  >
    <v-icon
      :color="contentColor"
      :small="$vuetify.breakpoint.mobile"
    >
      {{ icon }}
    </v-icon>
    <div :class="!$vuetify.breakpoint.mobile && icon? 'ml-1': ''">
      <slot />
    </div>
  </v-btn>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

export default {
  name: 'RectangleButton',
  props: {
    icon: { type: String, default: '' },
    disabled: Boolean,
    outlined: Boolean,
    isLoading: Boolean,
    type: { type: String, default: 'button' },
    contentColor: { type: String, required: false, default: '' },
    color: { type: String, required: true },
  },
};
</script>
