<template>
  <v-dialog
    v-model="dialog"
    :max-width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
    @keydown.esc="cancel"
  >
    <v-card
      class="px-8 py-4"
      variant="elevated"
      :width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
      :loading="isLoading"
    >
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ $t('deconve.muteNotifications') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>

      <v-card-text
        class="px-4 mt-2"
        style="padding-bottom: 0px"
      >
        <div class="d-flex justify-space-between align-center mt-4">
          <span
            class="font-weight-medium caption text-sm-body-1 mb-1"
          >
            {{ $t('deconve.video') }}
          </span>
        </div>
        <div class="d-flex flex-wrap">
          <span
            class="caption text-sm-body-2"
            style="max-width: 100%"
          >
            {{ videoName }}
          </span>
        </div>
        <div class="d-flex justify-space-between align-center mt-4">
          <span
            class="font-weight-medium caption text-sm-body-1 mb-n3"
          >
            {{ $t('deconve.reviewNotificationAs') }}
          </span>
        </div>
        <v-radio-group
          v-model="isSamePerson"
          style="gap: 5px"
          class="caption text-sm-body-2"
        >
          <v-radio
            v-for="(item, index) in revisions"
            :key="index"
            :label="$t(item.label)"
            :value="item.value"
          />
        </v-radio-group>
        <div class="d-flex justify-space-between align-center">
          <span
            class="font-weight-medium caption text-sm-body-1 mb-n3"
          >
            {{ $t('deconve.muteNotificationFor') }}
          </span>
        </div>
        <v-radio-group
          v-model="selectedPeriod"
          @change="getTimeForSilenceNotifications"
        >
          <v-card
            v-for="(period, index) in periods"
            :key="index"
            class="mb-2 caption text-sm-body-4"
            height="48px"
            style="display: flex; align-items: center;"
          >
            <v-radio
              :key="index"
              :label="$t(period.label)"
              :value="period.value"
              class="pl-2"
            />
          </v-card>
        </v-radio-group>
        <v-alert
          v-if="selectedPeriod"
          border="left"
          type="warning"
          :icon="false"
          outlined
          text
          :max-width="$vuetify.breakpoint.mobile ? '100%' : '450px'"
        >
          <v-row align="center">
            <v-col
              cols="1"
            >
              <v-checkbox v-model="warningCheckbox" />
            </v-col>
            <v-col
              cols="11"
              style="padding-left: 20px"
            >
              {{ $t('deconve.notificationsSilencerAlert', [$t(`deconve.${selectedPeriod}`)]) }}
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <rectangle-button
          v-if="!$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          data-cy="membermanager-cancel"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          :disabled="!warningCheckbox"
          data-cy="membermanager-confirm"
          color="primary"
          @clicked="confirm"
        >
          {{ $t('deconve.confirm') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { DATE_TIME_OPTIONS, getDateAndTimeRange } from '@/utils/dateTimeRange';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';

export default {
  name: 'NotificationsReviewDialog',
  components: {
    RectangleButton,
    SquareButton,
  },
  data: () => ({
    revisions: [
      { label: 'deconve.positiveNotification', value: 'yes' },
      { label: 'deconve.negativeNotification', value: 'no' },
      { label: 'deconve.unreviewedNotification', value: 'unreviewed' },
    ],
    periods: [
      { label: 'deconve.nextFiveMinutes', value: DATE_TIME_OPTIONS.nextFiveMinutes },
      { label: 'deconve.nextFifteenMinutes', value: DATE_TIME_OPTIONS.nextFifteenMinutes },
      { label: 'deconve.nextThirtyMinutes', value: DATE_TIME_OPTIONS.nextThirtyMinutes },
      { label: 'deconve.nextHour', value: DATE_TIME_OPTIONS.nextHour },
      { label: 'deconve.nextTwoHours', value: DATE_TIME_OPTIONS.nextTwoHours },
    ],
    dialog: false,
    warningCheckbox: false,
    resolve: null,
    reject: null,
    isSamePerson: null,
    videoId: null,
    personId: null,
    selectedPeriod: null,
    periodNotificationsMuted: null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      getVideo: 'faceid/getVideo',
      getPerson: 'faceid/getPerson',
    }),
    videoName() {
      const video = this.getVideo(this.videoId);

      return video?.name || this.videoId;
    },
    personName() {
      const person = this.getPerson(this.personId);

      return person?.name || this.personId;
    },
  },
  methods: {
    ...mapActions({
      addPersonReviewSettings: 'faceid/addPersonReviewSettings',
    }),
    open({ isSamePerson, videoId, personId }) {
      this.isSamePerson = isSamePerson;
      this.videoId = videoId;
      this.personId = personId;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getTimeForSilenceNotifications(value) {
      const { endDate } = getDateAndTimeRange(value);

      this.periodNotificationsMuted = endDate;
    },
    confirm() {
      this.isLoading = true;

      const reviewSettings = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        video_id: this.videoId,
        until: this.periodNotificationsMuted,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_same_person: this.isSamePerson,
      };

      this.addPersonReviewSettings({ personId: this.personId, reviewSettings }).then(() => {
        this.isLoading = false;
        this.dialog = false;
        this.resolve(true);
      });
    },
    cancel() {
      this.resolve(true);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
  textarea:focus {
    outline: 0;
  }
</style>
