<template>
  <v-row
    class="mt-1 mx-0"
  >
    <v-alert
      style="flex:1;"
      outlined
      :color="color"
      :icon="icon"
      border="left"
    >
      <div class="text-h6">
        {{ historyAction }}
      </div>
      <div
        class="d-flex justify-space-between"
        color="neutral"
      >
        <span class="neutral--text text-body-2">{{ $t('deconve.by') }}
          <v-progress-circular
            v-if="isLoading"
            class="pl-8"
            indeterminate
            color="neutral"
            size="15"
            width="2"
          />
          <strong>{{ userInfo }}</strong>
          <i18n
            v-if="!userInfo && !isLoading"
            :loading="true"
            path="deconve.userNotFound"
          >
            <b>{{ createdBy }}</b>
          </i18n>
        </span>
        <span class="neutral--text text-body-2">
          {{ $moment(createdAt).format('DD/MM/YYYY HH:mm:ss') }}
        </span>
      </div>
    </v-alert>
  </v-row>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import {
  mdiCheckboxMarkedCircleOutline as checkIcon,
  mdiEyeCheckOutline as eyeCheck,
  mdiRefresh as refreshIcon,
  mdiDelete as deleteIcon,
} from '@mdi/js';
import { types } from '../store/socket/types';
import { getUserInfo } from '../api/index';

export default {
  name: 'HistoryItem',
  props: {
    action: { type: String, required: true },
    createdBy: { type: String, required: true },
    createdAt: { type: String, required: true },
  },
  data: () => ({
    isLoading: false,
    userInfo: '',
  }),
  computed: {
    historyAction() {
      switch (this.action) {
        case types.COM_DECONVE_FACEID_NOTIFICATION_CREATED: return this.$t('deconve.created');
        case types.COM_DECONVE_FACEID_NOTIFICATION_REVIEWED: return this.$t('deconve.reviewed');
        case types.COM_DECONVE_FACEID_NOTIFICATION_MONITORED: return this.$t('deconve.monitored');
        case types.COM_DECONVE_FACEID_NOTIFICATION_UPDATED: return this.$t('deconve.updated');
        case types.COM_DECONVE_FACEID_NOTIFICATION_TRASHED: return this.$t('deconve.movedToTrash');
        case types.COM_DECONVE_FACEID_NOTIFICATION_RESTORED: return this.$t('deconve.restored');
        case types.COM_DECONVE_FACEID_PERSON_CREATED: return this.$t('deconve.created');
        case types.COM_DECONVE_FACEID_PERSON_UPDATED: return this.$t('deconve.updated');
        case types.COM_DECONVE_FACEID_PERSON_TRASHED: return this.$t('deconve.movedToTrash');
        case types.COM_DECONVE_FACEID_PERSON_RESTORED: return this.$t('deconve.restored');
        default: return '';
      }
    },
    color() {
      switch (this.action) {
        case types.COM_DECONVE_FACEID_NOTIFICATION_TRASHED: return 'error';
        case types.COM_DECONVE_FACEID_PERSON_TRASHED: return 'error';
        default: return 'info';
      }
    },
    icon() {
      switch (this.action) {
        case types.COM_DECONVE_FACEID_NOTIFICATION_CREATED:
          return checkIcon;
        case types.COM_DECONVE_FACEID_NOTIFICATION_REVIEWED:
          return checkIcon;
        case types.COM_DECONVE_FACEID_NOTIFICATION_MONITORED:
          return eyeCheck;
        case types.COM_DECONVE_FACEID_NOTIFICATION_UPDATED:
          return refreshIcon;
        case types.COM_DECONVE_FACEID_NOTIFICATION_TRASHED:
          return deleteIcon;
        case types.COM_DECONVE_FACEID_NOTIFICATION_RESTORED:
          return refreshIcon;
        case types.COM_DECONVE_FACEID_PERSON_CREATED:
          return checkIcon;
        case types.COM_DECONVE_FACEID_PERSON_UPDATED:
          return refreshIcon;
        case types.COM_DECONVE_FACEID_PERSON_TRASHED:
          return deleteIcon;
        case types.COM_DECONVE_FACEID_PERSON_RESTORED:
          return refreshIcon;
        default: return '';
      }
    },
  },
  watch: {
    createdBy() {
      this.getUserInfoById();
    },
  },
  created() {
    this.getUserInfoById();
  },
  methods: {
    getUserInfoById() {
      this.isLoading = true;
      getUserInfo(this.createdBy).then((user) => {
        this.userInfo = user.name || '';
        this.isLoading = false;
      });
    },
  },
};
</script>
