<template>
  <v-row>
    <v-col>
      <v-row
        class="d-flex mb-2"
        style="flex: 1"
      >
        <rectangle-button
          v-if="isEditMode && images.length > 0"
          outlined
          icon="mdi-cards-variant"
          color="neutral"
          @clicked="sortPersonImages"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.person.sortImages') }}
          </div>
        </rectangle-button>
      </v-row>
      <v-row>
        <div
          data-cy="image-list"
          class="d-flex"
          style="flex: 1; flex-wrap: wrap"
        >
          <template v-if="!isLoading">
            <image-list-content
              v-for="(img, i) in images"
              :key="i"
              :score-status="scoreStatus"
              :img="img"
              :index="i"
              @set-image="setImage"
            />
          </template>
          <template v-else-if="!isEditMode">
            <v-skeleton-loader
              v-for="n in 5"
              :key="n"
              type="image"
              :style="{
                'aspect-ratio': '1',
                width: `${imageSize}px`,
                height: `${imageSize}px`,
                margin: '4px',
              }"
            />
          </template>
        </div>
        <v-sheet
          v-if="!isSuperProfile && isEditMode"
          elevation="0"
          class="d-flex justify-center align-center dropbox pa-6"
        >
          <input
            type="file"
            multiple
            data-cy="input-image"
            accept="image/png, image/jpeg"
            class="input-file"
            required
            @click="clearInputFiles"
            @change="$emit('load-images', $event.target.files)"
          >
          <div style="display: flex; flex-direction: column; align-items: center">
            <v-icon
              :size="$vuetify.breakpoint.mobile? 28 : 32"
              class="mb-4"
              color="info"
            >
              mdi-image-multiple-outline
            </v-icon>
            <span
              style="color: #8F93FF; text-align: center"
              class="d-flex align-center body-2 text-sm-body-1"
            >
              {{ $t('deconve.fileInput') }}
            </span>
          </div>
        </v-sheet>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import ImageListContent from '@/components/ImageListContent.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ImageList',
  components: {
    ImageListContent,
    RectangleButton,
  },
  props: {
    images: { type: Array, required: true },
    isSuperProfile: { type: Boolean, default: false },
    isEditMode: Boolean,
    scoreStatus: Boolean,
    isLoading: Boolean,
  },
  computed: {
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 100;
        case 'sm': return 148;
        case 'md': return 156;
        case 'lg': return 168;
        case 'xl': return 200;
        default: return 200;
      }
    },
  },
  methods: {
    ...mapActions({
      sortPersonImages: 'faceid/sortPersonImages',
    }),
    setImage(index) {
      this.$emit('set-crop-image', index);
    },
    clearInputFiles(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    },
  },
};
</script>

<style>
  .dropbox {
    outline: 2px dashed #8F93FF; /* the dash box */
    outline-offset: -10px;
    background: 'transparentBackground';
    padding: 4px;
    min-height: 200px; /* minimum height */
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .dropbox:hover {
    background: #EFEFEF;
  }
  .dropbox:active {
    background: #dbdbdb;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    height: 200px;
    width: 100%;
    position: absolute;
    cursor: pointer;
  }
</style>
