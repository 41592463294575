<template>
  <v-col>
    <v-progress-linear
      v-if="isLoading"
      :indeterminate="true"
    />

    <v-row
      v-if="showImageCarousel"
      class="mb-3"
    >
      <carousel
        :key="selectedPersonId"
        :index="0"
        :images="selectedPersonImages"
        :profile-image="selectedPersonProfileImage"
        :profile-name="selectedPersonName"
        :profile-id="selectedPersonId"
        show-card-title
        is-profile-groups
        :is-related-profiles="isRelatedProfiles"
        card-border-color
        :number-of-images="numberOfImages"
        @downloadImage="downloadImage"
        @downloadOriginalImage="downloadOriginalImage"
        @addImageToProfile="addImageToPersonProfile"
        @close="showImageCarousel = false"
      />
    </v-row>

    <people-list
      :is-loading="isLoading"
      :people="profilesToShow"
      @onSelectPerson="onSelectPerson"
    />

    <v-snackbar
      v-model="alert"
      :color="alertColor"
    >
      {{ alertMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="resetAlert"
        >
          <v-icon color="white">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-col>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import PeopleList from '@/components/PeopleList.vue';
import Carousel from '@/components/Carousel.vue';
import { mapActions, mapGetters } from 'vuex';
import { downloadImage as downloadImageHelper } from '@/api';

export default {
  name: 'ProfileListViewer',
  components: {
    PeopleList,
    Carousel,
  },
  props: {
    profilesToShow: { type: Array, required: true },
    isLoading: { type: Boolean, required: true },
    isProfileGroups: { type: Boolean, default: false },
    isRelatedProfiles: { type: Boolean, default: false },
  },
  data() {
    return {
      alert: false,
      alertMessage: '',
      alertColor: '',
      showImageCarousel: false,
      selectedPerson: undefined,
      selectedPersonImages: [],
      selectedPersonProfileImage: '',
    };
  },
  computed: {
    ...mapGetters({
      personImages: 'faceid/personImages',
    }),
    numberOfImages() {
      return this.selectedPersonImages.length;
    },
    selectedPersonName() {
      return this.selectedPerson?.name || '';
    },
    selectedPersonId() {
      return this.selectedPerson?.id || '';
    },
  },
  watch: {
    profilesToShow() {
      this.onFilterChanged();
    },
  },
  methods: {
    ...mapActions({
      fetchPerson: 'faceid/fetchPerson',
      addImageToProfile: 'faceid/addImageToProfile',
      isImageNameValid: 'faceid/isImageNameValid',
    }),
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
    },
    onSelectPerson(index) {
      const person = this.profilesToShow[index];

      // Updating the selected person does not update the carousel selected index.
      // Instead of create a method in the carousel component, we just avoid to fetch the person
      // data again
      if (this.selectedPerson?.id === person?.id) {
        this.showImageCarousel = true;
        return;
      }

      this.fetchPerson(person.id).then((result) => {
        this.selectedPerson = result;

        this.selectedPersonImages = result.images.map((image) => ({
          thumbnailUrl: image.thumbnail_url,
          mediumQualityUrl: image.medium_quality_url,
          originalUrl: image.original_url,
          Url: image.thumbnail_url,
          info: image.faces[0] ? image.faces[0].bounding_box : undefined,
          mediumQuality: image.medium_quality,
          original: image.original,
          originalHeight: image.original_height,
          originalWidth: image.original_width,
          thumbnail: image.thumbnail,
        }));

        this.downloadProfileImage();
        this.downloadImage(0);
        this.showImageCarousel = true;
      });
    },
    downloadProfileImage() {
      const { profile_image_url: profileImageUrl } = this.selectedPerson;

      downloadImageHelper(profileImageUrl).then((image) => {
        this.selectedPersonProfileImage = image;
      });
    },
    downloadImage(index) {
      const personImage = this.selectedPersonImages[index];

      if (!Reflect.has(personImage, 'frame')) {
        const { thumbnailUrl, mediumQualityUrl } = personImage;

        downloadImageHelper(thumbnailUrl).then((image) => {
          Reflect.set(personImage, 'frameThumbnail', image);
          this.selectedPersonImages.splice(index, 1, personImage);
        });

        downloadImageHelper(mediumQualityUrl).then((image) => {
          Reflect.set(personImage, 'frame', image);
          this.selectedPersonImages.splice(index, 1, personImage);
        });
      }
    },
    downloadOriginalImage(index) {
      return new Promise((resolve) => {
        const personImage = this.selectedPersonImages[index];

        if (!Reflect.has(personImage, 'originalFrame')) {
          const { originalUrl } = personImage;

          downloadImageHelper(originalUrl).then((image) => {
            Reflect.set(personImage, 'originalFrame', image);
            this.selectedPersonImages.splice(index, 1, personImage);
            resolve(image);
          });
        } else {
          const { originalFrame } = personImage;

          resolve(originalFrame);
        }
      });
    },
    removeFileNameExtension(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    addImageToPersonProfile(index) {
      const personImageName = this.selectedPersonImages[index].original;

      // Image name without the extension must be unique
      this.isImageNameValid(personImageName).then((isValid) => {
        if (isValid) {
          this.downloadOriginalImage(index).then((originalImage) => {
            this.addImageToProfile({
              name: personImageName,
              frame: originalImage,
              addImageToImageList: false,
            }).then((imageAdded) => {
              if (imageAdded) {
                this.showAlert('primary', this.$t('deconve.person.alerts.personEdited'));
              } else {
                this.showAlert('warn', this.$t('deconve.person.alerts.personEditFailed'));
              }
            });
          });
        } else {
          this.showAlert(
            'info',
            this.$t(
              'deconve.person.alerts.imageAlreadyAdded',
              [this.removeFileNameExtension(personImageName)],
            ),
          );
        }
      });
    },
    onFilterChanged() {
      this.selectedPerson = undefined;
      this.selectedPersonImages = [];
      this.selectedPersonProfileImage = '';
      this.showImageCarousel = false;
    },
  },
};
</script>
